<template>
  <v-dialog
    v-model="$store.state.dialog"
    width="50%"
    :fullscreen="isfullscreen"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card flat class="px-4 pt-4">
      <!-- dialog toolbar -->
      <v-toolbar height="50" flat>
        <h5>
          {{ $store.state.itemDetails.dialogType == "add" ? "إضافة" : "تعديل" }}
          {{ this.$route.meta.single }}
        </h5>
        <v-spacer />
        <v-btn small icon fab color="" @click="isfullscreen = !isfullscreen">
          <v-icon small>{{
            isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand"
          }}</v-icon>
        </v-btn>
        <v-btn
          @click="close()"
          fab
          small
          icon
          color="grey"
          :disabled="$global.state.loading"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="mx-4" />

      <!-- form body -->
      <v-form
        @submit.prevent="submit()"
        ref="form"
        lazy-validation
        v-model="valid"
      >
        <v-container>
          <v-card flat class="py-4 px-1">
            <v-row>
              <!-- name -->
              <v-col cols="12" md="6">
                <h6 class="mb-1">اسم الفرع <span class="required">*</span></h6>
                <v-text-field
                  v-model="body.name"
                  :rules="[$global.state.required()]"
                  required
                  outlined
                  hide-details="auto"
                  filled
                  dense
                  placeholder="فرع بابل الرئيسي"
                >
                </v-text-field>
              </v-col>

              <!-- description -->
              <v-col cols="12" md="6">
                <h6 class="mb-1">الوصف <span class="required">*</span></h6>
                <v-text-field
                  v-model="body.description"
                  :rules="[$global.state.required()]"
                  required
                  outlined
                  hide-details="auto"
                  filled
                  dense
                  placeholder="الوصف"
                >
                </v-text-field>
              </v-col>

              <!-- phoneNumber -->
              <v-col cols="12" md="6">
                <h6 class="mb-1">رقم الهاتف الاول 1</h6>
                <v-text-field
                  v-model="body.phoneNumber1"
                  v-mask="$phoneMask"
                  dir="ltr"
                  placeholder="+964 ### ### ####"
                  filled
                  dense
                  outlined
                  hide-details="auto"
                />
              </v-col>

              <!-- phoneNumber -->
              <v-col cols="12" md="6">
                <h6 class="mb-1">رقم الهاتف 2</h6>
                <v-text-field
                  v-model="body.phoneNumber2"
                  v-mask="$phoneMask"
                  dir="ltr"
                  placeholder="+964 ### ### ####"
                  filled
                  dense
                  outlined
                  hide-details="auto"
                />
              </v-col>

              <!-- phoneNumber -->
              <v-col cols="12" md="6">
                <h6 class="mb-1">رقم الواتساب</h6>
                <v-text-field
                  v-model="body.whatsapp"
                  v-mask="$phoneMask"
                  dir="ltr"
                  placeholder="+964 ### ### ####"
                  filled
                  dense
                  outlined
                  hide-details="auto"
                />
              </v-col>

              <!-- provinceId -->
              <v-col cols="12" md="6">
                <h6 class="mb-1">المحافظة <span class="required">*</span></h6>
                <v-autocomplete
                  v-model="body.provinceId"
                  item-text="name"
                  item-value="id"
                  :items="$global.state.provinces"
                  clearable
                  dense
                  filled
                  :rules="[$global.state.required()]"
                  placeholder="المحافظة"
                  no-data-text="لا توجد بيانات"
                  hide-details
                  outlined
                />
              </v-col>

              <!-- fullAddress -->
              <v-col cols="12" md="6">
                <h6 class="mb-1">العنوان الكامل</h6>
                <v-text-field
                  v-model="body.fullAddress"
                  :rules="[$global.state.required()]"
                  required
                  outlined
                  hide-details="auto"
                  filled
                  dense
                  placeholder="العنوان الكامل"
                />
              </v-col>
            </v-row>
            <v-divider class="ml- mt-8" />

            <!-- actions -->
            <div class="mt-4">
              <v-btn
                type="submit"
                :loading="$global.state.loading"
                color="primary"
                class="btn"
                :disabled="!valid"
              >
                حفظ
                <v-icon small right>fi fi-rr-disk</v-icon>
              </v-btn>
              <v-btn
                @click="close()"
                text
                class="mr-2 btn"
                :disabled="$global.state.loading"
              >
                إلغاء
              </v-btn>
            </div>
          </v-card>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    isfullscreen: false,
    valid: true,
    body: {},
  }),

  created() {
    this.reset();
  },

  mounted() {
    this.$eventBus.$on("fill-fields", () => {
      this.body = this.$store.state.itemDetails;
    });
  },

  methods: {
    reset() {
      this.body = {
        name: "",
        description: "",
        phoneNumber1: "",
        phoneNumber2: "",
        whatsapp: "",
        provinceId: 0,
        fullAddress: "",
        isActive: true,
      };
    },

    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.$store.state.itemDetails.dialogType == "add") {
          this.addItem();
        } else {
          this.editItem();
        }
      }
    },

    async addItem() {
      this.$global.state.loading = true;
      await this.$http
        .post(`${this.$route.meta.endPoint}`, this.body)
        .then((res) => {
          this.$eventBus.$emit(`refresh`);
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toastification/setToast", {
            message: `${err.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.$global.state.loading = false));
    },

    async editItem() {
      this.$global.state.loading = true;
      await this.$http
        .put(`${this.$route.meta.endPoint}/${this.body.id}`, this.body)
        .then((res) => {
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
          this.$eventBus.$emit(this.$route.path);
        })
        .catch((err) => {
          this.$store.dispatch("toastification/setToast", {
            message: `${err.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.$global.state.loading = false));
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
